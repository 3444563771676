@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .quill>.ql-container>.ql-editor.ql-blank::before {
        font-size: 20px;
        color: #0000;
    }

    .dark .quill>.ql-container>.ql-editor.ql-blank::before {
        font-size: 20px;
        color: #ffffff;
    }

    .quill>.ql-container>.ql-editor p{
        font-size: 20px;
        color: #0000;
    }

    .dark .quill>.ql-container>.ql-editor p{
        font-size: 20px;
        color: #ffffff;
    }
}