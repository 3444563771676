*{
  box-sizing: border-box;
}
body{
  color:#222;
}
img{
  width: 100%;
}
header a{
  text-decoration: none;
  color: inherit;
}
header a.logo{
  font-weight: bold;
  font-size: 1.8rem;
}

header nav{
  display: flex;
  gap: 20px;
}
div.posts div.post{
display: grid;
grid-template-columns:1fr;
gap: 20px;
margin-bottom: 30px;
}
@media screen and (min-width: 700px) {
  
  div.posts div.post{
  display: grid;
  grid-template-columns: .9fr  1fr;
  gap: 20px;
  margin-bottom: 30px;
  }
}
div.posts div.post .posttext h2{
  margin: 0;
  font-size: 1.5rem;
  text-decoration: none;

}
div.posts div.post .posttext a{
  text-decoration: none;
  color: inherit;
}
div.post p.info{
  margin: 6px 0;
  color:#888;
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}

div.post p.info a.author{
  color: #333;
}

div.post p.summary{
  margin: 15px 0;
  line-height: 1.5rem;
}

form.login, form.register{
  margin: 0 auto;
  max-width: 400px;

}
input{
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
}
button{
  width: 100%;
  display: block;
  background-color: #555;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 7px;
}

form.login h1, form.register h1{
  text-align: center;
}

div.post-page div.image{
  max-height: 200px;
  display: flex;
  overflow: hidden;
}
div.post-page div.image img{
  object-fit: cover;
  object-position: center;
}

div.post-page a{
  color: #333;
  text-decoration: underline;
}

div.post-page h1{
 text-align: center;
 margin: 10px 0 5px;
}

div.post-page time{
  text-align: center;
  display: block;
  font-size: 0.7rem;
  color: #aaa;
}
div.post-page .author{
  text-align: center;
  display: block;
  font-size: 0.7rem;
  color: #aaa;
  font-weight: bold;
}

div.post-page .content p{
  line-height: 1;
}
div.post-page .edit-row{
  text-align: center;
}
div.post-page a.edit-btn{
  background-color: #333;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  display: inline-flex;
  text-decoration: none;
  margin: 10px 0 10px;
  gap: 5px;
}
a svg{  
  height: 18px;
}

.sendchat-head .user-info h3{
  margin: 0;
  padding: 10px;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  vertical-align: middle;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#acacac;
  }
  28% {
    transform: translateY(-7px);
    background-color:#cfcfcf;
  }
  44% {
    transform: translateY(0px);
    background-color: #e2e2e2;
  }
}